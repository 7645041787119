<div class="page-header">
  <h1>{{ 'companyAccessRequests.title' | translate }}</h1>
  <p>{{ 'companyAccessRequests.titleMsg' | translate }}</p>
</div>

<div class="company-access-requests-page_body">
  <app-table
    #table
    [(data)]="data"
    [config]="tableConfig"
    [placeholderTemplate]="placeholderTemplate"
    [template]="rowTemplate"
  >
    <ng-template
      #rowTemplate
      let-accessRequest
    >
      <div class="requested-by">
        <user-logo
          [logoUrl]="accessRequest.requestFromUserLogoUrl"
          [user]="$any({ id: accessRequest.requestFromUserId })"
        />
        <div class="requested-by_info">
          <div>
            {{ accessRequest.requestFromUserFirstName }}
            {{ accessRequest.requestFromUserLastName }}
          </div>
          <div>
            <span>{{ accessRequest.requestFromUserTitle }}, </span>
            <span>{{ accessRequest.requestFromUserCompanyName }}</span>
          </div>
        </div>
      </div>
      <div>
        <company-logo [company]="$any({ logoUrl: accessRequest.requestToCompanyLogoUrl })" />
        <div class="f-bold ml-2">{{ accessRequest?.requestToCompanyName }}</div>
      </div>
      <div>
        <div class="f-bold">
          {{ accessRequest.createdAt | ccDateFormatDistance: { addSuffix: true } }}
        </div>
      </div>
      <div>
        <div>
          {{
            accessRequest.status !== 'WaitingEmailConfirmation'
              ? accessRequest.status
              : ('companyAccessRequests.requestStatus.pendingEmailConfirmation' | translate)
          }}
        </div>
      </div>
      <div class="table_actions justify-content-start">
        <button
          (click)="deny(accessRequest.id)"
          [appBtnLoading]=""
          [disabled]="isLoading || requestDenied(accessRequest)"
          class="btn btn-primary submit-btn"
        >
          {{ 'common.buttons.deny' | translate }}
        </button>
        <button
          (click)="approve(accessRequest)"
          [appBtnLoading]=""
          [disabled]="isLoading || requestApproved(accessRequest)"
          class="btn btn-primary submit-btn"
        >
          {{ 'common.buttons.approve' | translate }}
        </button>
      </div>
    </ng-template>
    <ng-template #placeholderTemplate>
      <div class="table_placeholder-message">
        <div>{{ 'common.strings.noMatchesFound' | translate }}</div>
      </div>
    </ng-template>
  </app-table>
</div>
